import update from 'immutability-helper';
import { IThresholdFMS, IThresholdState, ThresholdFmsType } from 'redux/types/fmsThresholdTypes';

const initialState = {
  ThresholdListType: {
    isLoading: false,
    data: [],
    message: '',
  },
  ThresholdPostType: {
    isLoading: false,
    data: {},
    message: '',
  },
  ThresholdDetail: {
    isLoading: false,
    data: {},
    message: '',
  },
  ThresholdPutType : {
    isLoading : false,
    data : {},
    message : ''
  }
};

export default (state: IThresholdState = initialState, { type, payload }: IThresholdFMS) => {
  switch (type) {
    case ThresholdFmsType.GET_LIST_THRESHOLD_START:
      return update(state, {
        ThresholdListType: { isLoading: { $set: true }, message: { $set: '' } },
      });
    case ThresholdFmsType.GET_LIST_THRESHOLD_SUCCESS:
      return update(state, {
        ThresholdListType: {
          isLoading: { $set: false },
          // @ts-ignore
          data: { $set: payload },
        },
      });
    case ThresholdFmsType.GET_LIST_THRESHOLD_FAILED:
      return update(state, {
        ThresholdListType: {
          isLoading: { $set: false },
          message: { $set: payload },
        },
      });
    case ThresholdFmsType.POST_NEW_THRESHOLD_START:
      return update(state, {
        ThresholdPostType: {
          isLoading: { $set: true },
          message: { $set: '' },
        },
      });
    case ThresholdFmsType.POST_NEW_THRESHOLD_SUCCESS:
      return update(state, {
        ThresholdPostType: {
          isLoading: { $set: false },
          // @ts-ignore
          data: { $set: payload },
        },
      });
    case ThresholdFmsType.POST_NEW_THRESHOLD_FAILED:
      return update(state, {
        ThresholdPostType: {
          isLoading: { $set: false },
          message: { $set: '' },
        },
      });
    case ThresholdFmsType.GET_DETAIL_THRESHOLD_START:
      return update(state, {
        ThresholdDetail: {
          isLoading: { $set: true },
          message: { $set: '' },
        },
      });
    case ThresholdFmsType.GET_DETAIL_THRESHOLD_SUCCESS:
      return update(state, {
        ThresholdDetail: {
          isLoading: { $set: false },
          // @ts-ignore
          data: { $set: payload },
        },
      });
    case ThresholdFmsType.GET_DETAIL_THRESHOLD_FAILED:
      return update(state, {
        ThresholdDetail : {
          isLoading : {$set : false},
          message : {$set : payload}
        }
      });
      case ThresholdFmsType.PUT_THRESHOLD_START:
        return update(state, {
          ThresholdPutType: {
            isLoading: { $set: true },
            message: { $set: '' },
          },
        });
      case ThresholdFmsType.PUT_THRESHOLD_SUCCESS:
        return update(state, {
          ThresholdPutType: {
            isLoading: { $set: false },
            // @ts-ignore
            data: { $set: payload },
          },
        });
      case ThresholdFmsType.PUT_THRESHOLD_FAILED:
        return update(state, {
          ThresholdPutType: {
            isLoading: { $set: false },
            message: { $set: '' },
          },
        });
    default:
      return state;
  }
};
