import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { getCookie, getRealCookie } from '../utils/cookie';
import { routePath } from 'utils/exception';

function PublicRoute({ component: Component, ...rest }) {
  const isLogin = getRealCookie('token-web-admin') || getCookie('token-web-admin');
  // const isLogin = localStorage.getItem('token_keycloak');
  return (
    <Route
      {...rest}
      render={(props) => (!isLogin ? <Component {...props} /> : <Redirect to={`${routePath}/dashboard`} />)}
    />
  );
}

export default PublicRoute;
