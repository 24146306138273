export enum ThresholdFmsType {
  GET_LIST_THRESHOLD_START = 'GET_LIST_THRESHOLD_START',
  GET_LIST_THRESHOLD_SUCCESS = 'GET_LIST_THRESHOLD_SUCCESS',
  GET_LIST_THRESHOLD_FAILED = 'GET_LIST_THRESHOLD_FAILED',
  POST_NEW_THRESHOLD_START = 'POST_NEW_THRESHOLD_START',
  POST_NEW_THRESHOLD_SUCCESS = 'POST_NEW_THRESHOLD_SUCCESS',
  POST_NEW_THRESHOLD_FAILED = 'POST_NEW_THRESHOLD_FAILED',
  GET_DETAIL_THRESHOLD_START = 'GET_DETAIL_THRESHOLD_START',
  GET_DETAIL_THRESHOLD_SUCCESS = 'GET_DETAIL_THRESHOLD_SUCCESS',
  GET_DETAIL_THRESHOLD_FAILED = 'GET_DETAIL_THRESHOLD_FAILED',
  PUT_THRESHOLD_START = 'PUT_THRESHOLD_START',
  PUT_THRESHOLD_SUCCESS = 'PUT_THRESHOLD_SUCCESS',
  PUT_THRESHOLD_FAILED = 'PUT_THRESHOLD_FAILED',
}

export interface IThresholdState {
  ThresholdListType: InterfaceThresholdListTypes;
  ThresholdPostType: InterfaceThresholdPostTypes;
  ThresholdDetail: InterfaceThresholdDetailTypes;
  ThresholdPutType: InterfaceThresholdPostTypes;
}

export interface InterfaceThresholdListTypes {
  isLoading: boolean;
  data: ApiResponseThresholdList[];
  message: string;
}

export interface InterfaceThresholdPostTypes {
  isLoading: boolean;
  data: ApiResponseThresholdPost;
  message: string;
}

export interface InterfaceThresholdDetailTypes {
  isLoading: boolean;
  data: ApiResponseThresholdDetail;
  message: string;
}

export interface ApiResponseThresholdList {
  id?: number;
  source?: string;
  source_no?: string;
  source_date?: string;
  type_id?: string;
  type?: string;
  start_date?: string;
  end_date?: string;
  status_id?: string;
  status?: string;
  created_at?: string;
  created_by?: string;
  updated_by?: string;
  fms_parameter?: any;
}

export interface ApiResponseThresholdDetail {
  id?: number;
  source?: string;
  source_no?: string;
  source_date?: string;
  source_file_url?: string;
  type_id?: string;
  type?: string;
  start_date?: string;
  end_date?: string;
  status_id?: string;
  status?: string;
  created_at?: string;
  created_by?: string;
  updated_by?: string;
  fms_parameter?: FmsParameter[];
}

export interface FmsParameter {
  id?: number;
  treshold_id?: number;
  parameter_type?: string;
  parameter_name?: string;
  note?: string;
  status?: string;
  comp_id?: number;
  selected_conditions?: string | number;
  condition?: Conditions;
}

export interface Conditions {
  daily?: string;
  monthly?: string;
  weekly?: string;
  long_distance?: string;
  time_difference?: string;
  transaction_sequence?: string;
  average_time?: string;
  total_frequency?: string;
}

export interface ApiResponseThresholdPost {
  id?: number;
}

interface IGetListThresholdStart {
  type: ThresholdFmsType.GET_LIST_THRESHOLD_START;
  payload: null;
}

interface IGetListThresholdSuccess {
  type: ThresholdFmsType.GET_LIST_THRESHOLD_SUCCESS;
  payload: IThresholdState;
}

interface IGetListThresholdFailed {
  type: ThresholdFmsType.GET_LIST_THRESHOLD_FAILED;
  payload: string;
}

interface IGetDetailThresholdStart {
  type: ThresholdFmsType.GET_DETAIL_THRESHOLD_START;
  payload: null;
}

interface IGetDetailThresholdSuccess {
  type: ThresholdFmsType.GET_DETAIL_THRESHOLD_SUCCESS;
  payload: IThresholdState;
}

interface IGetDetailThresholdFailed {
  type: ThresholdFmsType.GET_DETAIL_THRESHOLD_FAILED;
  payload: string;
}

interface IPostThresholdStart {
  type: ThresholdFmsType.POST_NEW_THRESHOLD_START;
  payload: null;
}

interface IPostThresoldSuccess {
  type: ThresholdFmsType.POST_NEW_THRESHOLD_SUCCESS;
  payload: IThresholdState;
}

interface IPostThresholdFailed {
  type: ThresholdFmsType.POST_NEW_THRESHOLD_FAILED;
  payload: string;
}

interface IPutThresholdStart {
  type: ThresholdFmsType.PUT_THRESHOLD_START;
  payload: null;
}

interface IPutThresoldSuccess {
  type: ThresholdFmsType.PUT_THRESHOLD_SUCCESS;
  payload: IThresholdState;
}

interface IPutThresholdFailed {
  type: ThresholdFmsType.PUT_THRESHOLD_FAILED;
  payload: string;
}

type IListThresholdType =
  | IGetListThresholdStart
  | IGetListThresholdSuccess
  | IGetListThresholdFailed
  | IGetDetailThresholdStart
  | IGetDetailThresholdSuccess
  | IGetDetailThresholdFailed
  | IPostThresholdStart
  | IPostThresoldSuccess
  | IPostThresholdFailed
  | IPutThresholdStart
  | IPutThresoldSuccess
  | IPutThresholdFailed;

export type IThresholdFMS = IListThresholdType;
