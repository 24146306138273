/* eslint-disable import/no-anonymous-default-export */
import update from 'immutability-helper';
import { IRegistered, IRegisteredMerchantState, RegisteredMerchantTypes } from 'redux/types/registeredTypes';

const initialState = {
  NewMerchant: {
    isLoading: false,
    data: {},
    message: '',
  },
  detailNewMerchant: {
    isLoading: false,
    data: {},
    message: '',
  },
  ProdMerchant: {
    isLoading: false,
    data: {},
    message: '',
  },
  SandboxMerchant: {
    isLoading: false,
    data: {},
    message: '',
  },
  ApproveSandbox: {
    isLoading: false,
    message: '',
  },
  ApproveProduction: {
    isLoading: false,
    message: '',
  },
  CompleteDocument: {
    isLoading: false,
    message: '',
  },
  Export: {
    isLoading: false,
    message: '',
  },
};

export default (state: IRegisteredMerchantState = initialState, { type, payload }: IRegistered) => {
  switch (type) {
    case RegisteredMerchantTypes.GET_LIST_NEW_MERCHANT_START:
      return update(state, { NewMerchant: { isLoading: { $set: true } } });
    case RegisteredMerchantTypes.GET_LIST_NEW_MERCHANT_SUCCESS:
      return update(state, {
        NewMerchant: {
          isLoading: { $set: false },
          // idk why this got error yet
          // @ts-ignore
          data: { $set: payload },
        },
      });
    case RegisteredMerchantTypes.GET_LIST_NEW_MERCHANT_FAILED:
      return update(state, {
        NewMerchant: {
          isLoading: { $set: false },
          message: { $set: payload },
        },
      });
    case RegisteredMerchantTypes.GET_DETAIL_NEW_MERCHANT_START:
      return update(state, {
        detailNewMerchant: { isLoading: { $set: true }, data: { $set: {} } },
      });
    case RegisteredMerchantTypes.GET_DETAIL_NEW_MERCHANT_SUCCESS:
      return update(state, {
        detailNewMerchant: {
          isLoading: { $set: false },
          message: { $set: '' },
          // @ts-ignore
          data: { $set: payload },
        },
      });
    case RegisteredMerchantTypes.GET_DETAIL_NEW_MERCHANT_FAILED:
      return update(state, {
        detailNewMerchant: {
          isLoading: { $set: false },
          message: { $set: payload },
        },
      });
    case RegisteredMerchantTypes.GET_LIST_PROD_MERCHANT_START:
      return update(state, { ProdMerchant: { isLoading: { $set: true } } });
    case RegisteredMerchantTypes.GET_LIST_PROD_MERCHANT_SUCCESS:
      return update(state, {
        ProdMerchant: {
          isLoading: { $set: false },
          // @ts-ignore
          data: { $set: payload },
        },
      });
    case RegisteredMerchantTypes.GET_LIST_PROD_MERCHANT_FAILED:
      return update(state, {
        ProdMerchant: {
          isLoading: { $set: false },
          message: { $set: payload },
        },
      });
    case RegisteredMerchantTypes.GET_LIST_SANDBOX_MERCHANT_START:
      return update(state, { SandboxMerchant: { isLoading: { $set: true } } });
    case RegisteredMerchantTypes.GET_LIST_SANDBOX_MERCHANT_SUCCESS:
      return update(state, {
        SandboxMerchant: {
          isLoading: { $set: false },
          // @ts-ignore
          data: { $set: payload },
        },
      });
    case RegisteredMerchantTypes.GET_LIST_SANDBOX_MERCHANT_FAILED:
      return update(state, {
        SandboxMerchant: {
          isLoading: { $set: false },
          message: { $set: payload },
        },
      });
    case RegisteredMerchantTypes.APPROVE_PRODUCTION_MERCHANT_START:
      return update(state, {
        ApproveProduction: { isLoading: { $set: true } },
      });
    case RegisteredMerchantTypes.APPROVE_PRODUCTION_MERCHANT_SUCCESS:
      return update(state, {
        ApproveProduction: {
          isLoading: { $set: false },
          message: { $set: payload },
        },
      });
    case RegisteredMerchantTypes.APPROVE_PRODUCTION_MERCHANT_FAILED:
      return update(state, {
        ApproveProduction: {
          isLoading: { $set: false },
          message: { $set: payload },
        },
      });
    case RegisteredMerchantTypes.APPROVE_SANDBOX_MERCHANT_START:
      return update(state, { ApproveSandbox: { isLoading: { $set: true } } });
    case RegisteredMerchantTypes.APPROVE_SANDBOX_MERCHANT_SUCCESS:
      return update(state, {
        ApproveSandbox: {
          isLoading: { $set: false },
          message: { $set: payload },
        },
      });
    case RegisteredMerchantTypes.APPROVE_SANDBOX_MERCHANT_FAILED:
      return update(state, {
        ApproveSandbox: {
          isLoading: { $set: false },
          message: { $set: payload },
        },
      });
    case RegisteredMerchantTypes.COMPLETE_DOCUMENT_MERCHANT_START:
      return update(state, { CompleteDocument: { isLoading: { $set: true } } });
    case RegisteredMerchantTypes.COMPLETE_DOCUMENT_MERCHANT_SUCCESS:
      return update(state, {
        CompleteDocument: {
          isLoading: { $set: false },
          message: { $set: payload },
        },
      });
    case RegisteredMerchantTypes.COMPLETE_DOCUMENT_MERCHANT_FAILED:
      return update(state, {
        CompleteDocument: {
          isLoading: { $set: false },
          message: { $set: payload },
        },
      });
    default:
      return state;
  }
};
