import provider, { IProvider } from 'provider';
import { routePath } from './exception';
import MutationTypes from 'provider/methods';
import { endpoints } from 'api/endpoints';
import { sessionClear, setCookie } from './cookie';
import Cookies from 'js-cookie';
import { JWTdata } from 'redux/types/authTypes';
import jwtDecode from 'jwt-decode';

type param_list = {
  response_type: string;
  client_id: string;
  redirect_uri: string;
  scope: string;
  grant_type: string;
  client_secret: string;
  code: string;
  refresh_token: string;
};

type keycloak_error = { error: string; error_description: string };
type param_refresh = Pick<param_list, 'refresh_token' | 'scope'> & {
  'access_token': string;
  'expires_in': number;
  'id_token': string;
  'not-before-policy': number;
  'refresh_expires_in': number;
  'session_state': string;
  'token_type': string;
};

type ResponseToken = {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  token_type: string;
  id_token: string;
  session_state: string;
  scope: string;
} & keycloak_error;

const kcUrl = process.env.REACT_APP_REALM_KEYCLOAK;
const client_id = process.env.REACT_APP_CLIENT_ID_KEYCLOAK;
const client_secret = process.env.REACT_APP_CLIENT_SECRET_KEYCLOAK;

export const keycloakLogin = () => {
  // provide config / payload params to keycloak
  const params: Partial<param_list> = {
    response_type: 'code',
    client_id,
    redirect_uri: process.env.REACT_APP_URL_WEB.slice(0, -1) + routePath + 'authenticating',
    scope: 'openid',
  };

  // define the urls
  const loginUrl = kcUrl + `auth?${new URLSearchParams(params)}`;
  window.location.href = loginUrl; // redirect url
};

export const handleLoginResponse = () => {
  const url = kcUrl + 'token';

  // get code from url params
  const code = new URLSearchParams(window.location.search).get('code');

  // validate code not null || undefined
  if (!code) {
    console.error('Authorization code is missing or invalid');
    return;
  }

  // define the params
  const params: Partial<param_list> = {
    grant_type: 'authorization_code',
    client_id,
    client_secret,
    redirect_uri: process.env.REACT_APP_URL_WEB.slice(0, -1) + routePath + 'authenticating',
    code,
  };

  // trigger authorization code from url params, (single use code)
  fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: new URLSearchParams(params),
  })
    .then((response) => response.json())
    .then((data: Awaited<Promise<Partial<ResponseToken>>>) => {
      if (!data.error) {
        // save to cookie with the expired time included in the response
        const expiresIn = new Date(new Date().getTime() + data.refresh_expires_in * 1000);
        Cookies.set('token-web-admin', data.access_token, { expires: expiresIn });
        Cookies.set('refresh_token', data.refresh_token, { expires: expiresIn });

        setTimeout(async () => {
          await getPermissionList();
          // window.close();
          // window.opener.location.href = process.env.REACT_APP_URL_WEB;
          window.location.href = routePath;
          // const redirect = full ? '/full/' : '/';
          // window.location.href = redirect;
        }, 200);
      } else throw new Error(data.error_description || data.error);
    })
    .catch((err) => {
      console.error('Error fetching access token:', err);
    });
};

export const refreshKeycoakToken = () => {
  const url = kcUrl + 'token';

  // define the params
  const params: Partial<param_list> = {
    grant_type: 'refresh_token',
    client_id,
    client_secret,
    refresh_token: Cookies.get('refresh_token'),
  };

  // handle refresh token by params
  fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: new URLSearchParams(params),
  })
    .then((res) => res.json())
    .then((data: Awaited<Promise<param_refresh & keycloak_error>>) => {
      if (data.access_token !== undefined) {
        const expiresIn = new Date(new Date().getTime() + data.refresh_expires_in + 5 * 60 * 1000);
        Cookies.set('token-web-admin', data.access_token, { expires: expiresIn });
        Cookies.set('refresh_token', data.refresh_token, { expires: expiresIn });
      }
      if (data.error !== undefined) {
        // if (data.error_description === 'Token is not active') {
        setTimeout(() => {
          sessionClear();
          window.location.replace(routePath + 'login');
        }, 1000);
        // }
      }
    })
    .catch((error) => {
      console.log({ error });
    });
};

export const keycloakLogout = () => {
  const url = kcUrl + 'logout';
  const params: Partial<param_list> = {
    client_id,
    client_secret,
  };

  // (optional) include the user's refresh token
  if (Cookies.get('refresh_token')) {
    Object.assign(params, { refresh_token: Cookies.get('refresh_token') });
  }

  fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: new URLSearchParams(params),
  })
    .then((res) => {
      if (res.status === 204) {
        Cookies.remove('token-web-admin');
        Cookies.remove('refresh_token');
        sessionClear();
        setTimeout(() => {
          window.location.replace(routePath + 'login');
        }, 200);
      } else console.error('Logout Gagal: ', res.status);
    })
    .catch((err) => {
      sessionClear();
      console.error('Error during logout : ', err);
      window.location.href = routePath + 'login';
    });
};

export const getPermissionList = async () => {
  const token = Cookies.get('token-web-admin');
  const objProvider: IProvider = { method: MutationTypes.GET, path: endpoints.permissionList };

  if (!token) window.location.replace(routePath);

  Object.assign(objProvider, { headers: { Authorization: `Bearer ${token}` } });
  const response = await provider(objProvider);

  try {
    if (!response) throw new Error('Network Error');
    if (response.data) {
      const res = response.data;
      if (res.code === 200) {
        setCookie('token-web-admin', res.data);
        let jwt: JWTdata = jwtDecode(res.data);
        if (jwt.Permission !== null) {
          setCookie('permission-web-admin', JSON.stringify(jwt.Permission));
        }
        window.location.replace(routePath);
      } else {
        console.log({ res });
      }
    } else throw new Error(`${response.status}- ${response.statusText}`);
  } catch (err) {
    console.log({ err });
  }
};
