import { AxiosError } from 'axios';

export enum RegisteredMerchantTypes {
  GET_LIST_NEW_MERCHANT_START = 'GET_LIST_NEW_MERCHANT_START',
  GET_LIST_NEW_MERCHANT_SUCCESS = 'GET_LIST_NEW_MERCHANT_SUCCESS',
  GET_LIST_NEW_MERCHANT_FAILED = 'GET_LIST_NEW_MERCHANT_FAILED',
  GET_DETAIL_NEW_MERCHANT_START = 'GET_DETAIL_NEW_MERCHANT_START',
  GET_DETAIL_NEW_MERCHANT_SUCCESS = 'GET_DETAIL_NEW_MERCHANT_SUCCESS',
  GET_DETAIL_NEW_MERCHANT_FAILED = 'GET_DETAIL_NEW_MERCHANT_FAILED',
  GET_LIST_PROD_MERCHANT_START = 'GET_LIST_PROD_MERCHANT_START',
  GET_LIST_PROD_MERCHANT_SUCCESS = 'GET_LIST_PROD_MERCHANT_SUCCESS',
  GET_LIST_PROD_MERCHANT_FAILED = 'GET_LIST_PROD_MERCHANT_FAILED',
  GET_LIST_SANDBOX_MERCHANT_START = 'GET_LIST_SANDBOX_MERCHANT_START',
  GET_LIST_SANDBOX_MERCHANT_SUCCESS = 'GET_LIST_SANDBOX_MERCHANT_SUCCESS',
  GET_LIST_SANDBOX_MERCHANT_FAILED = 'GET_LIST_SANDBOX_MERCHANT_FAILED',
  APPROVE_SANDBOX_MERCHANT_START = 'APPROVE_SANDBOX_MERCHANT_START',
  APPROVE_SANDBOX_MERCHANT_SUCCESS = 'APPROVE_SANDBOX_MERCHANT_SUCCESS',
  APPROVE_SANDBOX_MERCHANT_FAILED = 'APPROVE_SANDBOX_MERCHANT_FAILED',
  APPROVE_PRODUCTION_MERCHANT_START = 'APPROVE_PRODUCTION_MERCHANT_START',
  APPROVE_PRODUCTION_MERCHANT_SUCCESS = 'APPROVE_PRODUCTION_MERCHANT_SUCCESS',
  APPROVE_PRODUCTION_MERCHANT_FAILED = 'APPROVE_PRODUCTION_MERCHANT_FAILED',
  COMPLETE_DOCUMENT_MERCHANT_START = 'COMPLETE_DOCUMENT_MERCHANT_START',
  COMPLETE_DOCUMENT_MERCHANT_SUCCESS = 'COMPLETE_DOCUMENT_MERCHANT_SUCCESS',
  COMPLETE_DOCUMENT_MERCHANT_FAILED = 'COMPLETE_DOCUMENT_MERCHANT_FAILED',
  EXPORT_REGISTERED_MERCHANT_START = 'EXPORT_REGISTERED_MERCHANT_START',
  EXPORT_REGISTERED_MERCHANT_SUCCESS = 'EXPORT_REGISTERED_MERCHANT_SUCCESS',
  EXPORT_REGISTERED_MERCHANT_FAILED = 'EXPORT_REGISTERED_MERCHANT_FAILED',
}

// State
export interface IRegisteredMerchantState {
  NewMerchant: {
    isLoading: boolean;
    data: MerchantData;
    message: string;
  };
  detailNewMerchant: {
    isLoading: boolean;
    data: DetailNewMerchantInterface;
    message: string;
  };
  ProdMerchant: {
    isLoading: boolean;
    data: MerchantData;
    message: string;
  };
  SandboxMerchant: {
    isLoading: boolean;
    data: MerchantData;
    message: string;
  };
  Export: {
    isLoading: boolean;
    message?: string;
  };
  ApproveSandbox?: ApprovalData;
  ApproveProduction?: ApprovalData;
  CompleteDocument?: ApprovalData;
}

// Data
interface MerchantData {
  data_merchant?: merchant_detail[];
  next?: number;
  pages?: number;
  previous?: number;
  total_records?: number;
}
export interface MerchantDetailData {
  code?: string;
  date_insert?: string;
  date_register?: string;
  date_sandbox?: string;
  date_update?: string;
  id?: number;
  management_type?: string;
  name?: string;
  package_type?: number;
  parent?: string;
  state?: string;
  status?: string;
}

export interface merchant_detail {
  address?: string;
  city?: string;
  client_id?: number;
  code?: string;
  country?: string;
  date_insert?: string;
  date_update?: string;
  deleted?: number;
  id?: number;
  management_type?: string;
  name?: string;
  package_type?: number;
  parent?: string;
  province?: string;
  status?: number;
  thema_list?: string;
  thema_pic?: string;
  zip?: string;
}

export interface merchant_biz {
  akta_url?: string;
  bank?: string;
  bank_acc_name?: string;
  bank_acc_no?: string;
  bank_acct_url?: string;
  bis_category_code?: string;
  bis_type_code?: string;
  bis_url?: string;
  date_register?: string;
  finance_email?: string;
  finance_phone?: string;
  is_doc_validated?: number;
  id?: number;
  is_developer?: number;
  ktp_name?: string;
  ktp_no?: string;
  ktp_url?: string;
  merchant_id?: number;
  nda_file_url?: string;
  nda_no?: string;
  nda_date?: string;
  note?:string;
  npwp_address?: string;
  npwp_name?: string;
  npwp_number?: string;
  npwp_url?: string;
  own_address?: string;
  own_citizen?: string;
  own_phone?: string;
  own_zip?: string;
  pks_file_url?: string;
  pks_date?: string;
  pks_no?: string;
  state?: number;
  skm_url?: string;
  tech_email?: string;
  tech_phone?: string;
}

interface ApprovalData {
  isLoading: boolean;
  message: string;
}
interface merchant {
  merchant: merchant_detail;
  merchant_biz: merchant_biz;
}

export interface payments {
  chanels?: chanel[];
  id?: number;
  picture?: {
    url?: string;
  };
}

interface chanel {
  id?: number;
  name?: string;
  page_id?: number;
  picture?: {
    url?: string;
  };
}

export interface DetailNewMerchantInterface {
  email_register_account?: string;
  payments?: payments[];
  merchant?: merchant;
}

export interface CompleteDocumentForm {
  NdaDate?: Date | null;
  NdaNumber?: string;
  PksDate?: null | Date;
  PksNumber?: string;
  Note?: string;
  Validated?: number;
  MerchantId?: number;
  AktaFile?: File | null;
  SkFile?: File | null;
  BankAccFile?: File | null;
  NDA?: File | null;
  PKS?: File | null;
  MerchantCode?: number;
  BankName?: string;
  BankAccountNumber?: string;
  BankAccountName?: string;
}

export interface locationCompleteDocument {
  hash?: string;
  key?: string;
  pathname?: string;
  search?: string;
  state?: {
    MerchantCode?: number;
    merchantId?: number;
    is_validate?: number;
    status_merchant?: number;
  };
}
interface filesPdf {
  file?: null | ArrayBuffer | string;
  pageNumber?: null | number;
  page?: null | number;
}
export interface previewPDF {
  AktaPendirian?: filesPdf;
  SKPendirian?: filesPdf;
  BukuRekening?: filesPdf;
  PKSfile?: filesPdf;
  NDA?: filesPdf;
}

// Action
interface IGetNewMerchantListStart {
  type: RegisteredMerchantTypes.GET_LIST_NEW_MERCHANT_START;
  payload: null;
}
interface IGetNewMerchantListSuccess {
  type: RegisteredMerchantTypes.GET_LIST_NEW_MERCHANT_SUCCESS;
  payload: MerchantData;
}
interface IGetNewMerchantListFailed {
  type: RegisteredMerchantTypes.GET_LIST_NEW_MERCHANT_FAILED;
  payload: any;
}
interface IGetDetailNewMerchantStart {
  type: RegisteredMerchantTypes.GET_DETAIL_NEW_MERCHANT_START;
  payload: null;
}
interface IGetDetailNewMerchantSuccess {
  type: RegisteredMerchantTypes.GET_DETAIL_NEW_MERCHANT_SUCCESS;
  payload: DetailNewMerchantInterface;
}
interface IGetDetailNewMerchantFailed {
  type: RegisteredMerchantTypes.GET_DETAIL_NEW_MERCHANT_FAILED;
  payload: any;
}
interface IGetProdMerchantStart {
  type: RegisteredMerchantTypes.GET_LIST_PROD_MERCHANT_START;
  payload: null;
}
interface IGetProdMerchantSuccess {
  type: RegisteredMerchantTypes.GET_LIST_PROD_MERCHANT_SUCCESS;
  payload: MerchantData;
}
interface IGetProdMerchantFailed {
  type: RegisteredMerchantTypes.GET_LIST_PROD_MERCHANT_FAILED;
  payload: any;
}
interface IGetSandboxMerchantStart {
  type: RegisteredMerchantTypes.GET_LIST_SANDBOX_MERCHANT_START;
  payload: null;
}
interface IGetSandboxMerchantSuccess {
  type: RegisteredMerchantTypes.GET_LIST_SANDBOX_MERCHANT_SUCCESS;
  payload: MerchantData;
}
interface IGetSandboxMerchantFailed {
  type: RegisteredMerchantTypes.GET_LIST_SANDBOX_MERCHANT_FAILED;
  payload: any;
}

interface IAppoveProdMerchantStart {
  type: RegisteredMerchantTypes.APPROVE_PRODUCTION_MERCHANT_START;
  payload: null;
}
interface IAppoveProdMerchantSuccess {
  type: RegisteredMerchantTypes.APPROVE_PRODUCTION_MERCHANT_SUCCESS;
  payload: any; // sementara
}
interface IAppoveProdMerchantFailed {
  type: RegisteredMerchantTypes;
  payload: any;
}

interface IApproveSandboxStart {
  type: RegisteredMerchantTypes.APPROVE_SANDBOX_MERCHANT_START;
  payload: null;
}
interface IApproveSandboxSuccess {
  type: RegisteredMerchantTypes.APPROVE_SANDBOX_MERCHANT_SUCCESS;
  payload: any; // sementara
}
interface IApproveSandboxFailed {
  type: RegisteredMerchantTypes.APPROVE_SANDBOX_MERCHANT_FAILED;
  payload: any;
}

interface ICompleteMerchantStart {
  type: RegisteredMerchantTypes.COMPLETE_DOCUMENT_MERCHANT_SUCCESS;
  payload: null;
}
interface ICompleteMerchantSuccess {
  type: RegisteredMerchantTypes.COMPLETE_DOCUMENT_MERCHANT_SUCCESS;
  payload: any; // sementara
}
interface ICompleteMerchantFailed {
  type: RegisteredMerchantTypes.COMPLETE_DOCUMENT_MERCHANT_SUCCESS;
  payload: { error: AxiosError };
}

type INewMerchant = IGetNewMerchantListFailed | IGetNewMerchantListStart | IGetNewMerchantListSuccess;

type IDetailNewMerchant = IGetDetailNewMerchantFailed | IGetDetailNewMerchantStart | IGetDetailNewMerchantSuccess;

type IProdMerchant = IGetProdMerchantFailed | IGetProdMerchantStart | IGetProdMerchantSuccess;

type ISandboxMerchant = IGetSandboxMerchantFailed | IGetSandboxMerchantStart | IGetSandboxMerchantSuccess;

type IApproveProdMerchant = IAppoveProdMerchantFailed | IAppoveProdMerchantStart | IAppoveProdMerchantSuccess;

type IApproveSandBoxMerchant = IApproveSandboxStart | IApproveSandboxSuccess | IApproveSandboxFailed;

type ICompleteMerchant = ICompleteMerchantFailed | ICompleteMerchantStart | ICompleteMerchantSuccess;

export type IRegistered =
  | INewMerchant
  | IDetailNewMerchant
  | IProdMerchant
  | ISandboxMerchant
  | IApproveProdMerchant
  | IApproveSandBoxMerchant
  | ICompleteMerchant;
